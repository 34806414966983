import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "stepper",
      "style": {
        "position": "relative"
      }
    }}>{`Stepper`}</h1>
    <p>{`Stepper is a visual representation of user progress through a set of steps. They guide the user through a number of steps in order to complete a specified process, generally a wizard.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Display the steps sequentially in order from left to right.`}</li>
      <li parentName="ul">{`Allow the user to return to a previous step to review their data submission.`}</li>
      <li parentName="ul">{`When possible, use some indication of the status of the step, like when it's complete/validated, or incomplete/invalidated.`}</li>
    </ul>
    <h2 {...{
      "id": "step-status",
      "style": {
        "position": "relative"
      }
    }}>{`Step status`}</h2>
    <table>
  <tbody>
    <tr>
      <th width={160}>Completed</th>
      <td>Presented when users achieve the minimum required to continue to the next step.</td>
    </tr>
    <tr>
      <th>Active</th>
      <td>Presented when the step is currently active and not validated yet.</td>
    </tr>
    <tr>
      <th>Incompleted</th>
      <td>Steps not reached yet.</td>
    </tr>
  </tbody>
    </table>
    <h2 {...{
      "id": "examples",
      "style": {
        "position": "relative"
      }
    }}>{`Examples`}</h2>
    <h3 {...{
      "id": "manually-controlled-state",
      "style": {
        "position": "relative"
      }
    }}>{`Manually controlled state`}</h3>
    <p>{`The state of the stepper is manually controlled via `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Step`}</code>{` component props.`}</p>
    <Demo src='pages/components/stepper/StepperDefault' mdxType="Demo" />
    <h3 {...{
      "id": "controling-state-with-usestepperstate-hook",
      "style": {
        "position": "relative"
      }
    }}>{`Controling state with `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`useStepperState`}</code>{` hook`}</h3>
    <p>{`Optionally, the hook `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useStepperState`}</code>{` can be used to control the stepper state.`}</p>
    <Demo src='pages/components/stepper/StepperHook' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      